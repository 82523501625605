// ** React Imports
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})

BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { GlobalLoaderProvider } from './utility/context/GlobalLoader'
import { ClientProvider } from './utility/context/Client'
import { BiometricsProvider } from './utility/context/Biometrics'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React yup validator
import * as yup from 'yup'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

/**
 * HID WebSdk
 * @see https://hidglobal.github.io/digitalpersona-devices/
 */
import './webSdk'

yup.setLocale({
  mixed: {
    required: '${path} é um campo obrigatório!',
    notType: '${path} é um campo obrigatório!'
  },
  string: {
    min: '${path} deve ter mais que ${min} caracteres!',
    max: '${path} deve ter no máximo ${max} caracteres!',
    email: '${path} deve ser um e-mail válido!'
  }
})

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { GlobalLoaderProviderWrapper } from './utility/GlobalLoaderHelper'
import { OauthProvider } from './utility/context/Oauth'
import { SSOProvider } from './utility/context/SSO'
import { IntegrationProvider } from './utility/context/Integration'
import { NavbarProvider } from 'utility/context/Navbar'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

document.title = "IntegraDoor - WebApp"

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ErrorBoundary>
        <OauthProvider>
          <SSOProvider>
            <NavbarProvider>
              <OauthProvider>
                <IntegrationProvider>
                  <GlobalLoaderProvider>
                    <AbilityContext.Provider value={ability}>
                      <ClientProvider>
                        <ThemeContext>
                          <BiometricsProvider>
                            <GlobalLoaderProviderWrapper>
                              <LazyApp />
                              <ToastContainer newestOnTop />
                            </GlobalLoaderProviderWrapper>
                          </BiometricsProvider>
                        </ThemeContext>
                      </ClientProvider>
                    </AbilityContext.Provider>
                  </GlobalLoaderProvider>
                </IntegrationProvider>
              </OauthProvider>
            </NavbarProvider>
          </SSOProvider>
        </OauthProvider>
      </ErrorBoundary>
    </Suspense >
  </Provider >,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()