import { useState, createContext } from 'react'

const default_fingers = [
    { label: 'Polegar Direito', value: 1, collected: false },
    { label: 'Indicador Direito', value: 2, collected: false },
    { label: 'Medio Direito', value: 3, collected: false },
    { label: 'Polegar Esquerdo', value: 6, collected: false },
    { label: 'Indicador Esquerdo', value: 7, collected: false },
    { label: 'Medio Esquerdo', value: 8, collected: false }
]

const BiometricsContext = createContext()

const BiometricsProvider = ({ children }) => {
    const [sample, setSample] = useState(false)
    const [selected_finger, setSelectedFinger] = useState(false)
    const [startedCollection, setStartedCollection] = useState(false)
    const [driverError, setDriverError] = useState(false)
    const [finishedCollection, setFinishedCollection] = useState(false)
    const [cryptedData, setCryptedData] = useState(null)
    const [tokenBiometria, setTokenBiometria] = useState(null)
    const [tipoCadastro, setTipoCadastro] = useState(null)
    const [cadastroPessoa, setCadastroPessoa] = useState(null)
    const [dedos, setDedos] = useState(default_fingers)
    const [session, setSession] = useState(null)
    const [is_active, setIsActive] = useState(false)
    const [app_origem, setAppOrigem] = useState(null)
    const [versao_aplicacao, setVersaoAplicacao] = useState(null)
    const [info_adicional, setInfoAdicional] = useState(null)
    const [resX, setResX] = useState(null)
    const [resY, setResY] = useState(null)
    const [viewState, setViewState] = useState(null)

    const handleResetBiometrics = () => {
        setSample(false)
        setSelectedFinger(false)
        setStartedCollection(false)
        setDriverError(false)
        setFinishedCollection(false)
        setCryptedData(null)
        setTokenBiometria(null)
        setTipoCadastro(null)
        setCadastroPessoa(null)
        setDedos(default_fingers)
        setAppOrigem(null)
        setVersaoAplicacao(null)
        setInfoAdicional(null)
        setResX(null)
        setResY(null)
        setSession(null)
        setViewState(null)
    }

    const value = {
        is_active,
        setIsActive,
        sample,
        setSample,
        selected_finger,
        setSelectedFinger,
        startedCollection,
        setStartedCollection,
        driverError,
        setDriverError,
        finishedCollection,
        setFinishedCollection,
        cryptedData,
        setCryptedData,
        tokenBiometria,
        setTokenBiometria,
        tipoCadastro,
        setTipoCadastro,
        cadastroPessoa,
        setCadastroPessoa,
        handleResetBiometrics,
        dedos,
        setDedos,
        session,
        setSession,
        app_origem,
        setAppOrigem,
        versao_aplicacao,
        setVersaoAplicacao,
        info_adicional,
        setInfoAdicional,
        resX,
        setResX,
        resY,
        setResY,
        viewState,
        setViewState
    }

    return (
        <BiometricsContext.Provider value={value}>
            {children}
        </BiometricsContext.Provider>
    )
}

export { BiometricsContext, BiometricsProvider }
